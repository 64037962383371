import React, { useState, useEffect } from 'react';
import Utils from '@/utils/utils';
import Form from './components/Form';

export default function Register(props) {

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const adviser=Utils.getCookies('adviser');
    const adviserToken=Utils.getCookies('match_token');
    if(adviser && adviserToken) {
      window.location.replace('/register/myEnroll/');
    } else {
      setIsShow(true)
    }
  }, []);

  if(isShow) {
    return <Form form={ FORMITEMLIST }  />
  } else {
    return null
  }
}

const AGREEMENT_TYPE = {
  linkUrl: 'https://oss.5rs.me/oss/uploadfe/html/rays_yinsi.html'
};

const AgreementBox = (
  <span className='AgreementBox'>
    <input
      className='checkbox'
      type="checkbox"
      name="isAgreed"
    />
    <span className="ml10">我已阅读并同意<a className="text-primary" target="_blank" href={AGREEMENT_TYPE.linkUrl}>《隐私政策》</a></span>
  </span>  
)

//登录
const FORMITEMLIST = {
  type:'login',className:'login',tabs:[],list:[

    {label:'账号',placeholder:'请输入账号',name:'userName',type:'input',required:true,minLength:2,maxLength:20},
    {label:'密码',placeholder:'请输入密码',name:'pwd',type:'input',required:true,type2:'password'},
    {label:'忘记密码？',name:'forget',type:'link',to:'forget', subtitle: AgreementBox },
    {label:'登录',name:'loginbtn',type:'button',type2:'login'},
    {label:'请使用RAYS编辑账号登录'},
    {label:'如暂无RAYS编辑账号，请联系<a href="tel:15926459426">15926459426</a>（雷老师）进行开通。', html: true}
  ]
}